// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-bone-soup-spices-js": () => import("./../../../src/pages/product/bone-soup-spices.js" /* webpackChunkName: "component---src-pages-product-bone-soup-spices-js" */),
  "component---src-pages-product-chicken-meat-curry-powder-js": () => import("./../../../src/pages/product/chicken-meat-curry-powder.js" /* webpackChunkName: "component---src-pages-product-chicken-meat-curry-powder-js" */),
  "component---src-pages-product-chicken-meat-korma-powder-js": () => import("./../../../src/pages/product/chicken-meat-korma-powder.js" /* webpackChunkName: "component---src-pages-product-chicken-meat-korma-powder-js" */),
  "component---src-pages-product-chili-powder-js": () => import("./../../../src/pages/product/chili-powder.js" /* webpackChunkName: "component---src-pages-product-chili-powder-js" */),
  "component---src-pages-product-fish-curry-powder-js": () => import("./../../../src/pages/product/fish-curry-powder.js" /* webpackChunkName: "component---src-pages-product-fish-curry-powder-js" */),
  "component---src-pages-product-red-curry-powder-js": () => import("./../../../src/pages/product/red-curry-powder.js" /* webpackChunkName: "component---src-pages-product-red-curry-powder-js" */),
  "component---src-pages-product-shrimp-crab-dried-fish-curry-powder-js": () => import("./../../../src/pages/product/shrimp-crab-dried-fish-curry-powder.js" /* webpackChunkName: "component---src-pages-product-shrimp-crab-dried-fish-curry-powder-js" */),
  "component---src-pages-product-soup-powder-js": () => import("./../../../src/pages/product/soup-powder.js" /* webpackChunkName: "component---src-pages-product-soup-powder-js" */),
  "component---src-pages-product-soup-spices-js": () => import("./../../../src/pages/product/soup-spices.js" /* webpackChunkName: "component---src-pages-product-soup-spices-js" */),
  "component---src-pages-product-tumeric-powder-js": () => import("./../../../src/pages/product/tumeric-powder.js" /* webpackChunkName: "component---src-pages-product-tumeric-powder-js" */),
  "component---src-pages-products-curry-index-js": () => import("./../../../src/pages/products-curry/index.js" /* webpackChunkName: "component---src-pages-products-curry-index-js" */),
  "component---src-pages-products-korma-index-js": () => import("./../../../src/pages/products-korma/index.js" /* webpackChunkName: "component---src-pages-products-korma-index-js" */),
  "component---src-pages-products-soup-index-js": () => import("./../../../src/pages/products-soup/index.js" /* webpackChunkName: "component---src-pages-products-soup-index-js" */),
  "component---src-pages-products-tumeric-chili-index-js": () => import("./../../../src/pages/products-tumeric-chili/index.js" /* webpackChunkName: "component---src-pages-products-tumeric-chili-index-js" */),
  "component---src-pages-recipe-category-main-course-js": () => import("./../../../src/pages/recipe-category/main-course.js" /* webpackChunkName: "component---src-pages-recipe-category-main-course-js" */),
  "component---src-pages-recipe-category-side-dishes-js": () => import("./../../../src/pages/recipe-category/side-dishes.js" /* webpackChunkName: "component---src-pages-recipe-category-side-dishes-js" */),
  "component---src-pages-recipe-category-snacks-starters-js": () => import("./../../../src/pages/recipe-category/snacks-starters.js" /* webpackChunkName: "component---src-pages-recipe-category-snacks-starters-js" */),
  "component---src-pages-recipe-category-soup-js": () => import("./../../../src/pages/recipe-category/soup.js" /* webpackChunkName: "component---src-pages-recipe-category-soup-js" */),
  "component---src-pages-recipe-list-js": () => import("./../../../src/pages/recipe-list.js" /* webpackChunkName: "component---src-pages-recipe-list-js" */),
  "component---src-pages-recipe-main-js": () => import("./../../../src/pages/recipe-main.js" /* webpackChunkName: "component---src-pages-recipe-main-js" */),
  "component---src-pages-recipe-recipe-dish-js": () => import("./../../../src/pages/recipe/recipe-dish.js" /* webpackChunkName: "component---src-pages-recipe-recipe-dish-js" */),
  "component---src-pages-recipe-side-js": () => import("./../../../src/pages/recipe-side.js" /* webpackChunkName: "component---src-pages-recipe-side-js" */),
  "component---src-pages-recipe-snack-starter-js": () => import("./../../../src/pages/recipe-snack-starter.js" /* webpackChunkName: "component---src-pages-recipe-snack-starter-js" */)
}

